import { useState, useEffect } from 'react';
import { HeaderBar } from "./components/HeaderBar";
import { FooterBar } from "./components/FooterBar";
import { Total } from "./components/Total";
import { loadData } from "./loadData";
import { Deliveries } from "./containers/deliveries";
import { AgeDoses } from "./containers/agedoses";
import { Weeks } from "./containers/weeks";
import { Supplier } from './components/Supplier';
import { hideLoader } from "./utils";
import "./App.css";
import { Locations } from "./containers/locations";


function App() {
    const [summary, setSummary] = useState({});

    useEffect(() => {
        loadData().then((d) => {
        hideLoader();
        setSummary(d);
        });
    }, []);

    return (
        <div>
            <HeaderBar />
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">

                    </div>
                </div>
                <div style={{ padding: 20 }}></div>
                <div className="row position-powerbi" style={{ backgroundColor: '#5bc0de' }} >
                    <div className="col-12">
                        <div className="p-4 position-relative d-flex justify-content-center  h-100" style={{ backgroundColor: '#5bc0de', minHeight: 240 }}>
                            <div className="d-none  d-lg-block" style={{ height: 100, position: 'absolute', left: '20px', top: '20px' }}>
                                <img src="group_person.svg" alt="Logo" className="img-fluid" />
                            </div>
                            <div className="position-absolute center-logo">
                                <img src="sewasew-election-center-logo.svg" width="280" height="130" alt="Logo" />
                            </div>
                            <div className="text-white w-100" style={{ padding: "60px 0 0 0" }}>
                                <div className="w-100  h-100 align-items-center justify-content-center text-center">
                                    <h5 style={{ marginRight: 0, marginTop: 25, fontWeight: 600 }}>National Seats Distribution</h5>
                                    <div style={{fontSize: 16}}>The 6th national election will be held in 673 constituencies in the regions and city administrations</div>
                                    <div style={{ top: -10 }}>(more information at https://nebe.org.et/en/polling-stations)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Deliveries data={summary} />
                <AgeDoses data={summary} />
                <Supplier data={summary}></Supplier>
                <Locations data={summary} />

                <div className="row">
                    <div className="col-12 text-center pt-5 pb-3">This platform is contributed by {" "}
                        <a href="https://sewasew.com" target="_blank">sewasew.com</a>
                    </div>
                </div>
            </div>

            <FooterBar />

        </div>
    );
}

export default App;
