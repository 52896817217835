import {React, useEffect} from "react";
import * as $ from "jquery";
import DataTable from "datatables.net";
import "./Table.css";
import "../naturalSortAlgorithm";


$.DataTable = DataTable;
const columns = [
    {title: "Region", data: "area"},
    {title: "Polling Stations", data: "polling_stations"},
    {title: "HOPR Seats", data: "hopr_seats"},
    {title: "%", data: "percentage"},
];

export const Table = ({deliveryTableData}) => {
    deliveryTableData = deliveryTableData.filter(function( obj ) {
        return obj?.code !== "TOT";
    });

    // Add footer elements
    useEffect(() => {
        $("#datatable").find("tfoot")
        $("#datatable")
            .find("table")
            .append('<tfoot><th></th><th></th><th></th><th></th></tfoot>')
    }, [])

    useEffect(() => {

        $("#datatable")
            .find("table")
            .DataTable({
                dom:
                    "<'row'<'col-12'l>>" +
                    "<'row'<'col-12'tr>>",
                paging: false,
                searching: true,
                destroy: true,
                data: deliveryTableData,
                columns,
                language: {
                    decimal: ",",
                    thousands: "."
                },
                columnDefs: [
                    {
                        targets: 0,
                        width: '160px'
                    },
                    {type: 'natural', targets: 1},
                    {
                        "targets": [ ],
                        render: (data, type, row) => {
                            return Number(data).toLocaleString('en')
                        }
                    }
                ],
                footerCallback: (row, data, start, end, display) => {
                    var api = $("#datatable")
                        .find("table")
                        .DataTable();

                    // Remove the formatting to get integer data for summation
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            i.replace(/[$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };


                    let totalNationalSeats = api
                        .column(2, {search: 'applied'})
                        .data()
                        .reduce(function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(2).footer()).html(
                        totalNationalSeats.toLocaleString('en')
                    );

                    let totalPollingStations = api
                        .column(1, {search: 'applied'})
                        .data()
                        .reduce(function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(1).footer()).html(
                        totalPollingStations.toLocaleString('en')
                    );


                    $(api.column(0).footer()).html(
                        'Total'
                    );
                }
            });

    }, [deliveryTableData]);

    return (
        <div id="datatable">
            <table
                className="table h-100 table-borderless compact table-hover deliveries-table"
                cellSpacing="0"
                width="100%"
                style={{paddingBottom: 6}}
            />
        </div>
    );
};
