import React from "react";
import "../App.css";
import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "typeface-titillium-web";
import "typeface-roboto-mono";
import "typeface-lora";

export const HeaderBar = () => {
  return (
    <>
      {/* <Header small={false} theme="" type="slim">

      </Header> */}
      <div className="row m-0 mb-5" style={{ backgroundColor: '#5bc0de' }}>
        <div className="col-12 col-md-4 d-flex justify-content-md-center justify-content-sm-start logo-section pt-2 pb-2">
          <a href="https://nebe.org.et" className="text-decoration-none" target="_blank" rel="noreferrer">
            <img src="logo-nebe.png" height="4px" alt="Logo" className="logo pl-5 pr-2" />
          </a>
          <p className="pt-sm-0">
            <a href="https://nebe.org.et/" target="_blank" className="text-decoration-none" rel="noreferrer">
              National Election Board of Ethiopia
            </a>
          </p>
        </div>
        <div className="col-12 col-md-4 d-flex justify-content-md-center justify-content-sm-start logo-section pr-sm-0 pt-2 pb-2">
          <a className="text-decoration-none" href="https://am.sewasew.com/t/%E1%88%9D%E1%88%AD%E1%8C%AB-2013" target="_blank" rel="noreferrer">
            <img src="logo.png" height="4px" alt="Logo" className="logo pl-5 pr-2" />
          </a>
          <p className="pt-sm-0">
            <a className="text-decoration-none" href="https://am.sewasew.com/t/%E1%88%9D%E1%88%AD%E1%8C%AB-2013" target="_blank" rel="noreferrer">
              Sewasew - Bringing Africa's knowledge to the Internet
            </a>
          </p>
        </div>
        <div className="col-12 col-md-4 d-flex justify-content-md-center justify-content-sm-start logo-section pt-2 pb-2 pr-sm-3">
       {/*   <a href=" https://www.press.et/english/" target="_blank" rel="noreferrer">
            <img src="https://www.press.et/english/wp-content/uploads/2021/04/EPA-LogoRGB-280x300-1.jpg" height="4px" alt="Logo" className="logo pl-5 pr-2" />
          </a>
          <p className="pt-sm-0">
            <a href="https://www.press.et/english/" className="text-decoration-none" target="_blank" rel="noreferrer">
              Ethiopian Press Agency
              </a>
          </p>*/}

        </div>
        {/** TITLE + LOGO*/}
     {/*   <div className="col-12 d-flex flex-column justify-content-center mainBanner">
          <div className="d-flex justify-content-center">
            <img src="sewasew-election-center-logo.svg" alt="Logo" height="150px" className="main-logo" />
            <span className="pl-2 pt-5">Ethiopian Election 2013</span>
          </div>
        </div>*/}
      </div>
    </>
  );
};
