import {React, useEffect, useState} from "react";
import "../App.css";
import {isEmpty} from "lodash";
import {simulateClick} from './../utils';
import {BarChartSupplier} from "./BarChartsSupplier";

export const Supplier = (data) => {
    const [selectedLocationCategoryMap, setSelectedLocationCategoryMap] = useState(null);
    const [selectedSupplier, setelectedSupplier] = useState(null);
    const [deliveryBarChartData, setdeliveryBarChartData] = useState([]);
    const [totalSuplier, setTotalSuplier] = useState(0);

    const resetFilter = () => {
        setdeliveryBarChartData(data?.data?.allDosesSupplier);
        setTotalSuplier(data?.data?.totalSuplier);
        setelectedSupplier(null);
        setSelectedLocationCategoryMap(null);
        setTotalSuplier(data?.data?.totalSuplier);
    }

    useEffect(() => {
        if (!isEmpty(data)) {
            setdeliveryBarChartData(data?.data?.allDosesSupplier);
            setTotalSuplier(data?.data?.totalSuplier);
        }
    }, [data]);

    const handleDeliveryBarChartClick = (bar) => {
        if (selectedSupplier === bar) {
            resetFilter();
        } else if (selectedLocationCategoryMap) {
            resetFilter();
            simulateClick(bar.party);
        } else {
            handleRectClick(bar);
            setelectedSupplier(bar);
            setTotalSuplier(bar?.total);
        }
    }

    const handleRectClick = (currentRect) => {
        setelectedSupplier(currentRect)
    }

    return (
        <div className="row">
            <div className="col-12 col-md-12 h-100" style={{marginTop: 40}}>
                <div className="col-12 d-flex justify-content-center align-items-center p-5 bg-title-plot">
                    <h3 className="text-center">Number of seats won by party</h3>
                </div>
                <div className="col-12 col-md-12 h-100 p-0">

                    {/* Total Box - Mobile Layout */}
                    <div className="m-3 d-lg-none" style={{position: 'relative', background: '#5bc0de'}}>
                        <img src="sewasew-election-center-logo.svg" width="200" height="100" alt="Logo"
                             className="d-flex text-center" style={{position: "absolute", left: 0, top: -98}}/>

                    </div>
                    {/* // Total Box - Mobile Layout */}

                    {/* Total Box - Mobile Desktop */}
                    <div className="offset-md-9 col-md-3  d-none d-lg-block">
                        <div style={{position: 'relative', background: '#5bc0de', top: -70, left: -50,}}>
                            <img src="sewasew-election-center-logo.svg" width="250" height="100" alt="Logo"
                                 className="d-none d-lg-block"
                                 style={{position: "absolute", right: -45, top: -25, zIndex: 10}}/>

                        </div>
                    </div>
                    {/* // Total Box - Mobile Desktop */}
                </div>
            </div>

            {/* Supplier BarChart */}
            <div className="col-12 col-md-12 h-100 pb-5">
                <BarChartSupplier
                    title=""
                    xtitle="Vote results"
                    ytitle=""
                    width="800"
                    handleDeliveryBarChartClick={handleDeliveryBarChartClick}
                    height="300"
                    selected={selectedSupplier}
                    property={{xprop: "party", yprop: "total"}}
                    data={deliveryBarChartData}
                />
            </div>
            {/* Supplier BarChart */}
            <div className=" d-none d-sm-none d-md-block d-lg-none  col-md-6"></div>
        </div>
    );
};