import * as _ from 'lodash';

export const hideLoader = () => {
    let loading = document.getElementById('loader-custom');
    if (loading) loading.className = !loading.className.includes('d-none') ? loading.className + ' d-none' : loading.className;
}
export const showLoader = () => {
    let loading = document.getElementById('loader-custom');
    if (loading) loading.className = loading.className.includes('d-none') ? loading.className.replace('d-none', '') : loading.className;
}
export const sumDose = (acc, x) => acc + +x?.TML_DOSE_1 + +x?.TML_DOSE_2;
export const sumDoseXY = (y, z) => (acc, x) => acc + +x?.[y] + +x?.[z];
export const sumYear = (accumulator, currentValue) => accumulator + currentValue;
export const sumDoseX = (y) => (acc, x) => acc + +x?.[y];
export const maxX = (y) => (acc, x) => (x?.[y] > acc ? x?.[y] : acc);
export const age = ["16-19", "20-29", "30-39", "40-49", "50-59", "60-69", "70-79", "80-89", "90+"];
export const groupByAge = (array) => {
    let _age;
    if (Array.isArray(array) && array.length > 0) {
        _age = age.map(el => {
            let resMale = _.sum(array.filter(_el => _el.station.trim() === el).map(__el => __el.male));
            let resFemale = _.sum(array.filter(_el => _el.station.trim() === el).map(__el => __el.female));
            return {
                station: el,
                female: resFemale,
                male: resMale,
                total: resMale + resFemale
            };
        });
    }
    return _age;
}
export const mapArrayByProp = (array, prop) => array.map((e) => e[prop]);
export const reduceSum = (array) => array.reduce(sumYear);

export const aggrBy = (p) => (acc, x) => {
    let key = x[p];
    if (!acc[key]) {
        acc[key] = [];
    }
    acc[key].push(x);
    return acc;
};
export const filterBy = (y) => (z) => (x) => {
    return x[y] === z;
};
export const filterByArea = filterBy("area");
export const filterByAreaITA = filterByArea("ITA");
export const filterByAge = filterBy("TML_FASCIA_ETA");
export const mapBy = (y) =>
    Object.keys(y).map((x) => {
        return {[x]: {sum: y[x].reduce(sumDose, 0)}};
    });
export const mapByX = (y, z) =>
    Object.keys(y).map((x) => {
        return {[x]: y[x].reduce(sumDoseX(z), 0)};
    });
const inverse = (obj) => {
    let retobj = {};
    for (const key in obj) {
        retobj[obj[key]] = key;
    }
    return retobj;
};
export const simulateClick = (id) => {
    if (document.getElementById(id) && id) {
        let clickEvt = new MouseEvent('click', {
            'bubbles': true,
            'cancelable': true
        });
        document.getElementById(id).dispatchEvent(clickEvt);
    }
}
export const replaceArea = (x) => ({...x, area: areaMapping[x.area], code: x.area});
export const areaMapping = {
    AAA: "Addis Ababa",
    AFR: "Afar",
    AMH: "Amhara",
    BGZ: "Benshangul Gumuz",
    DDW: "Dire Dawa",
    GAM: "Gambella",
    HRR: "Harari",
    ORO: "Oromia",
    SID: "Sidama",
    SNNP: "SNNP",
    SOM: "Somali",
    TIG: "Tigray",

};
export const areaMappingReverse = inverse(areaMapping);

//
//   const tot2 = data.reduce(sumDoseXY("TML_DOSE_1", "TML_DOSE_2"), 0);
//   const areasRAW = data.reduce(aggrBy("TML_AREA"), {});
//   const tot_m = data.reduce(sumDoseX("TML_SESSO_M"), 0);
//   const tot_f = data.reduce(sumDoseX("TML_SESSO_F"), 0);
//   const areas = mapBy(areasRAW);
//   const fascia1619 = data.filter(filterByAge("16-19"));
//   const fascia2029 = data.filter(filterByAge("20-29"));
//   const totfascia1619 = fascia1619.reduce(sumDose, 0);
//   const totfascia2029 = fascia2029.reduce(sumDose, 0);
