import {sumDoseX, replaceArea, areaMapping} from "./utils";
import _ from "lodash";
import Moment from 'moment';

const sommVaxSummaryURL = `/data/somministrazioni-summary-regional-stats.json`;
const sommVaxDetailURL = `/data/candidate-count.json`;
const vaxSummaryURL = `/data/summary-regional-stats.json`;
const partyCandidates = `/data/party-candidates.json`;
const anagraficaSummaryURL = `/data/anagrafica-summary-regional-stats.json`;
const lastUpdateURL = `/data/last-update-dataset.json`;
const voteResults = `/data/vote-results.json`;
const plateaURL = `/data/platea.json`;

const elaborate = (data) => {

    console.log("#############4444", data.dataVaxLocations.data);


    // datatable and map
    const dataSupplier = data.dataSupplierDoses.data;
    const dataSomeVaxDetail = data.dataSommVaxDetail.data.map(replaceArea);

    const deliverySummary = data.dataVaxSummary.data.map(replaceArea);

    // categories and ages summary
    const dataVaxSomLatest = data?.dataSommVaxDetail?.data;

    const tot = _.sum(dataVaxSomLatest?.map((e) => e?.national_seats));

    let totalDoses = {
        national_seats: _.sum(dataVaxSomLatest?.map((e) => e?.national_seats)),
        regional_seats: _.sum(dataVaxSomLatest?.map((e) => e?.regional_seats)),
        national_seats_janssen: _.sum(
            dataVaxSomLatest
                ?.filter((e) => e.party === "Janssen")
                .map((e) => e?.national_seats)
        ),
        vax_somministrati: _.sum(
            dataSupplier
                ?.filter((e) => e?.update_time?.substr(0, 10) !== "2020-12-27")
                ?.map((_e) => _e?.won_seats)
        )?.toLocaleString("en"),
    };

    const groups = _.groupBy(dataSupplier, "party");
    let allDosesSupplier = Object.keys(groups).map((k) => {
        let groupByKey = groups[k].map((group) => group.won_seats);
        let sumTotalDoses = _.sum(groupByKey);
        return {total: sumTotalDoses, party: k, allDoses: groups[k]};
    });

    let totalSuplier = _.sum(allDosesSupplier.map((e) => e?.total));

    let allDosesMapByArea = _.groupBy(dataSupplier, "area");
    let doesesByArea = Object.keys(allDosesMapByArea).map((area) => {
        let groupByArea = allDosesMapByArea[area].map((dose) => dose.won_seats);
        let totalDosesByArea = _.sum(groupByArea);
        return {area: areaMapping[area], polling_stations: totalDosesByArea};
    });

    const deliveredByArea = _.groupBy(deliverySummary, "code");

    const locations = data.dataVaxLocations.data.map(replaceArea);

    let maxNumberOfLocations = 0;

    const locationsByRegion = _(data.dataVaxLocations.data.map(replaceArea))
        .groupBy("area")
        .map((items, area) => {
            maxNumberOfLocations =
                maxNumberOfLocations > items.length
                    ? maxNumberOfLocations
                    : items.length;
            return {area: area, locations: items.length};
        })
        .value();

    const totalDeliverySummary = _(data.dataSommVaxDetail.data.map(replaceArea))
        .groupBy("code")
        .map((items, code) => {
            const details = _.head(deliveredByArea[code]);
            return {
                code: code,
                area: _.head(items)?.area,
                percentage: (details.hopr_seats / 509 * 100).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }),
                hopr_seats: details.hopr_seats || 0,
                polling_stations: details.polling_stations || 0
            };
        })
        .value();

    const totalDeliverySummaryByAge = _(data.dataSommVaxDetail.data.map(replaceArea))
        .groupBy((i) => i["station"].toString().trim())
        .map((rows, age) => {
            const details = _(rows)
                .groupBy("code")
                .map((rowsData, code) => {
                    const polling_stations = _.sumBy(
                        rowsData,
                        (r) => r.male + r.female
                    );
                    const percentage =
                        polling_stations /
                        (_.head(deliveredByArea[code]).hopr_seats || 1);
                    return {
                        age: age,
                        polling_stations,
                        code: code,
                        hopr_seats: _.head(deliveredByArea[code]).hopr_seats || 0,
                        area: _.head(rowsData).area,
                        //details: rows
                    };
                })
                .value();

            return {
                age: age,
                details: details,
            };
        })
        .groupBy("age")
        .value();

    /* ages stack bar chart */
    let dosesAgesColor = {
        "National seats": "#519ae8",
    };

    let regionsDoses = {};
    let dosesAges = ["National seats"];
    let dosesAgesData = [];
    let dosesAgesRegionData = {};

    let agesTmp = {};

    for (let row of data.dataSommVaxDetail.data) {
        var key = row.party;

        if (!agesTmp.hasOwnProperty(key)) {
            agesTmp[key] = {
                "National seats": 0
            };
        }


        agesTmp[key]["National seats"] = row.national_seats;


        /* regions data */
        if (!regionsDoses.hasOwnProperty(row.area)) {
            regionsDoses[row.area] = {};
            regionsDoses[row.area][key] = {
                "National seats": 0,
            };
        } else {
            if (!regionsDoses[row.area].hasOwnProperty(key)) {
                regionsDoses[row.area][key] = {
                    "National seats": 0,
                };
            }
        }

        regionsDoses[row.area][key]["National seats"] = row.national_seats;

    }

    let ageDosesTotal = {};

    for (let row of Object.keys(agesTmp).sort().reverse()) {
        var entry = {
            label: row
        };
        entry["National seats"] = agesTmp[row]["National seats"];


        ageDosesTotal[entry['label']] = entry["National seats"];

        dosesAgesData.push(entry);
    }

    let secondDoses = {}
    for (let row of data.dataSommVaxDetail.data) {
        entry = {};
        if (secondDoses.hasOwnProperty(row.area)) {
            entry = secondDoses[row.area];
        }

        var secondDoseTmp = row.regional_seats;

        if (!secondDoses.hasOwnProperty(row.area)) {
            entry = {
                'code': row.area,
                'area': areaMapping[row.area],
                'somministrazioni': secondDoseTmp
            };
        } else {
            entry['somministrazioni'] = secondDoseTmp;
        }

        for (let rowAge of Object.keys(agesTmp)) {
            let keyAge = rowAge === 'over 80' ? 'fascia_over_80' : ('fascia_' + rowAge);
            if (entry.hasOwnProperty(keyAge)) {
                entry[keyAge] += (rowAge === row.station || (rowAge === 'over 80' && (row.station === '90+' || row.station === '80-89'))) ? secondDoseTmp : 0;
            } else {
                entry[keyAge] = (rowAge === row.station || (rowAge === 'over 80' && (row.station === '90+' || row.station === '80-89'))) ? secondDoseTmp : 0;
            }
        }
        secondDoses[row.area] = entry;
    }

    let secondDosesData = []; /* Array delle regioni avente numero somministrazioni seconde dosi globale e per fasce d'età */
    for (let region of Object.keys(secondDoses)) {
        secondDosesData.push(secondDoses[region]);
    }

    let secondDosesPlateaData = [];
    for (let region of Object.keys(secondDoses)) {
        let tmpElem = secondDoses[region];

        let entry = {};
        for (let subItem of Object.keys(tmpElem)) {
            entry[subItem] = tmpElem[subItem];
        }

        secondDosesPlateaData.push(entry);
    }

    for (let region of Object.keys(regionsDoses)) {
        let arrayTmp = [];

        for (let row of Object.keys(regionsDoses[region]).sort().reverse()) {
            entry = {
                label: row
            };
            entry["National seats"] = regionsDoses[region][row]["National seats"];

            arrayTmp.push(entry);
        }

        dosesAgesRegionData[region] = arrayTmp;
    }

    console.log(dosesAgesRegionData);

    // suppliers
    let spectrum = ["#0f69c9", "#4d99eb", "#77b2f2", "#b5d4f5", "#d1e0f0", "#edf2f7", "#ffffff"];
    let suppliersColor = {};
    let suppliers = [];

    for (let row of data.dataSommVaxDetail.data) {
        if (!suppliers.includes(row.party)) {
            suppliers.push(row.party);
            if ((suppliers.length - 1) < spectrum.length) {
                suppliersColor[row.party] = spectrum[suppliers.length - 1];
            } else {
                suppliersColor[row.party] = "#ffffff";
            }
        }
    }

    // all weeks
    let weeksMappingOptimation = {};
    var index = 0;

    let suppliersWeek = [];
    var date = new Date('2021-06-21'); // start date
    while (true) {
        let entry = {
            label: Moment(date).format('DD/MM'),
            from: Moment(date).format('YYYY-MM-DD'),
            labelfrom: Moment(date).format('DD/MM'),
            to: Moment(new Date(date.getTime() + 6 * 86400000)).format('YYYY-MM-DD'),
            labelto: Moment(new Date(date.getTime() + 6 * 86400000)).format('DD/MM'),
            total: 0
        };

        for (let supplier of suppliers) {
            entry[supplier] = 0;
        }

        weeksMappingOptimation[Moment(date).format('YYYY-MM-DD')] = index;
        weeksMappingOptimation[Moment(new Date(date.getTime() + 1 * 86400000)).format('YYYY-MM-DD')] = index;
        weeksMappingOptimation[Moment(new Date(date.getTime() + 2 * 86400000)).format('YYYY-MM-DD')] = index;
        weeksMappingOptimation[Moment(new Date(date.getTime() + 3 * 86400000)).format('YYYY-MM-DD')] = index;
        weeksMappingOptimation[Moment(new Date(date.getTime() + 4 * 86400000)).format('YYYY-MM-DD')] = index;
        weeksMappingOptimation[Moment(new Date(date.getTime() + 5 * 86400000)).format('YYYY-MM-DD')] = index;
        weeksMappingOptimation[Moment(new Date(date.getTime() + 6 * 86400000)).format('YYYY-MM-DD')] = index;
        index++;

        suppliersWeek.push(entry);

        date = new Date(date.getTime() + 7 * 86400000);

        if (date > new Date()) {
            break;
        }
    }

    // weeks data


    const timestamp = data.dataLastUpdate.last_updated;
    const aggr = {
        timestamp,
        tot,
        deliverySummary,
        locations,
        dataSomeVaxDetail,
        locationsByRegion,
        maxNumberOfLocations,
        allDosesSupplier,
        doesesByArea,
        totalSuplier,
        totalDoses,
        totalDeliverySummaryByAge,
        totalDeliverySummary,
        suppliersColor,
        suppliers,
        suppliersWeek,
        dosesAges,
        dosesAgesColor,
        dosesAgesData,
        dosesAgesRegionData,
        ageDosesTotal,
        secondDosesData,
        secondDosesPlateaData
    };
    return aggr;
};

export const loadData = async () => {
    const [
        resSommVaxSummary,
        resSommVaxDetail,
        resVaxSummary,
        resProfileSummaryURL,
        resVaxLocations,
        resLastUpdate,
        resSupplierDoses,
        resPlatea
    ] = await Promise.all([
        fetch(sommVaxSummaryURL),
        fetch(sommVaxDetailURL),
        fetch(vaxSummaryURL),
        fetch(anagraficaSummaryURL),
        fetch(partyCandidates),
        fetch(lastUpdateURL),
        fetch(voteResults),
        fetch(plateaURL)
    ]);

    const [
        dataSommVaxSummary,
        dataSommVaxDetail,
        dataVaxSummary,
        dataProfileSummary,
        dataVaxLocations,
        dataLastUpdate,
        dataSupplierDoses,
        dataPlatea
    ] = await Promise.all([
        resSommVaxSummary.json(),
        resSommVaxDetail.json(),
        resVaxSummary.json(),
        resProfileSummaryURL.json(),
        resVaxLocations.json(),
        resLastUpdate.json(),
        resSupplierDoses.json(),
        resPlatea.json()
    ]);

    return {
        ...elaborate({
            dataSommVaxSummary,
            dataSommVaxDetail,
            dataVaxSummary,
            dataProfileSummary,
            dataLastUpdate,
            dataVaxLocations,
            dataSupplierDoses,
            dataPlatea
        })
    };
};
